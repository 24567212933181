import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import "./calculator-pages.less"

const ROICalculatorPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const calculatorRef = useRef(null)

  useEffect(() => {
    // Check if calculator is loaded by looking at the data-loaded attribute
    const checkIfCalculatorLoaded = () => {
      if (
        calculatorRef.current &&
        calculatorRef.current.dataset.loaded === "true"
      ) {
        setIsLoading(false)
      } else {
        // Check again after a short delay
        setTimeout(checkIfCalculatorLoaded, 500)
      }
    }

    // Start checking after the component mounts
    const timeoutId = setTimeout(checkIfCalculatorLoaded, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        <script src="https://scripts.convertcalculator.com/embed.js" async />
      </Helmet>
      <SEO title="ROI Calculator" />
      <div id="roi-calculator-page">
        <div className="container">
          <div className="calculator-container">
            {isLoading && (
              <div className="loading-spinner">
                <div className="spinner"></div>
                <div className="loading-text">Loading calculator...</div>
              </div>
            )}
            <div
              className="calculator"
              data-calc-id="6AauPXDzueHDhmosJ"
              data-type="framed"
              ref={calculatorRef}
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ROICalculatorPage
